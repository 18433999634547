import { useStateContext } from '../contexts/ContextProvider';
import { Navbar, Sidebar, Menu } from './index';



const Layout = ({ children }) => {
    const { activeMenu, showRightSideMenu } = useStateContext();
    
    return (
        <div className='flex relative bg-main-dark-bg p-0'>
            <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
            </div>
            {activeMenu ? (
                <div className='w-56 fixed sidebar bg-secondary-dark-bg bg-white'>
                    <Sidebar />
                </div>
            ) : (
                <div className='w-0 bg-secondary-dark-bg'>
                    <Sidebar />
                </div>
            )}
            <div className={
                `bg-main-dark-bg  min-h-screen w-full`}>
                <div className='fixed md:static bg-main-dark-bg navbar w-full'>
                    <Navbar />
                </div>
                {showRightSideMenu && <Menu />}
                <div className='flex flex-wrap lg:flex-nowrap justify-center flex-col items-center'>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Layout;
