import { useState } from 'react';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import {Link} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const SignUp = () => {
  const [email, setEmail] =  useState('')
  const { signUp } = useStateContext();
  const [formValid, setFormValid] = useState(true)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    setEmail(email)
    if (email.match(/^[^ ]+@[^ ]+\.[a-z]{2,3}$/)) {
      setFormValid(false)
    } else {
      setFormValid(true)
    }
  }

  const createNewUser = async (email) => {
    setLoading(true)
    const response = await signUp(email);
    if (response.status === "Ok") {
    toast.error(response.message, {
        autoClose: 3000,
        theme: "dark",
        });
      navigate(`/get-otp/`)
    }
    toast.error(response.message, {
      autoClose: 3000,
      theme: "dark",
    });
    setLoading(false)
  }

  return (
    <>
      <ToastContainer />
      <div className="bg-secondary-dark-bg rounded-lg md:w-1/2 w-5/6 m-auto px-5 md:px-10 pb-10 pt-5">
        <Header category="Register" title={loading ? 'Loading...' : 'Sign Up'} />
        <label className="block mb-2 text-md font-medium text-gray-400">Email</label>
        <input 
          onChange={(e) => validateEmail(e.target.value)} 
          type="text" 
          className={`${!formValid ? 'border-green-500' : 'border-red-200'} text-sm rounded-lg block w-full p-2.5 bg-gray-700  text-gray-400`} 
          placeholder="m@m.m" 
          />
        <p className='mt-3 text-sm text-gray-400'>Log in <Link className="underline text-blue-600" to="/get-otp">here</Link>.</p>
        <button 
          disabled={formValid || loading} 
          onClick={() => createNewUser(email)} 
          className={`${!formValid ? 'bg-green-500' : 'bg-red-400'} mt-5 text-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>Sign Up</button>
      </div>
    </>
  );
};

export default SignUp;
