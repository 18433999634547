import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ValidateOTP = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('')
    const { validateOTP, setToken } = useStateContext();
    const [formValid, setFormValid] = useState(true)
    const [loading, setLoading] = useState(false);
    const { id } = useParams()

    const validateForm = (otp) => {
        setOtp(otp)
        if (otp.length === 6) {
            setFormValid(false)
        } else {
            setFormValid(true)
        }
    }

    const submitOTP = async (otp) => {
        setLoading(true)
        const response = await validateOTP(otp, id)
        if (response.status === "Ok") {
            setToken(response.jwt);
            Cookies.set('token', response.jwt, { expires: 7, path: '/' });
            navigate('/')
        }
        toast.error(response.message, {
            autoClose: 3000,
            theme: "dark",
        });
        setLoading(false)
    }

    return (
        <>
            <ToastContainer />
            <div className="bg-secondary-dark-bg rounded-lg md:w-1/2 w-5/6 m-auto px-5 md:px-10 pb-10 pt-5">
                <Header category="Login" title={loading ? 'Loading...' : 'Validate OTP'} />
                <label className="block mb-2 text-md font-medium text-gray-400">OTP</label>
                <input 
                    maxLength="6" 
                    onChange={(e) => validateForm(e.target.value)} 
                    type="text" 
                    className={`${!formValid 
                        ? 'border-green-500' 
                        : 'border-red-200'} text-gray-400 border bg-gray-700 border-gray-300 text-sm rounded-lg block w-full p-2.5`} placeholder="123456" required />
                <button 
                    disabled={formValid || loading} 
                    onClick={() => submitOTP(otp)} 
                    className={`${!formValid ? 'bg-green-500' : 'bg-red-400'} mt-5 text-white rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center`}>Validate OTP</button>
            </div>
        </>
    );
};

export default ValidateOTP;
