import React, { useState, useEffect } from 'react';
import { Layout } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { Link } from 'react-router-dom';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = () => {
  const [usersWealth, setUesrsWealth] = useState(0)
  const { getUsersWealth, getUsersAccounts } = useStateContext();
  const [accounts, setAccounts] = useState([]);
  const [data, setData] = useState({ labels: [], datasets: [] });


  useEffect(() => {
    const fetchUsersWealth = async () => {
      const data = await getUsersWealth();
      const accRes = await getUsersAccounts([]);
      setUesrsWealth(data.wealth);
      setAccounts(accRes.accounts);
      setData({
        labels: accounts.map(item => item.AccountName),
        datasets: [
          {
            label: 'Account Wealth',
            data: accounts.map(item => item.CurrentWealth),
            backgroundColor: accounts.map(item => item.AccountColor),
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: .5,
            responsive: true,
            fill: false
          }
        ]
      });
    };
    fetchUsersWealth();
  }, [usersWealth])

  return (
    <Layout>
      <div className='text-gray-200 bg-secondary-dark-bg h-22 rounded-xl w-3/4 mt-10 p-8 pt-9 m-3 mb-5'>
        <div className='flex justify-between items-center self-center'>
          {accounts.length === 0
            ? <Link className=" px-5 py-1 bg-blue-400 rounded-lg text-white" to="/account">Add an account</Link>
            : (
              <div>
                <p className="font-bold text-gray-400">Total</p>
                <p className="text-4xl">{usersWealth}</p>
              </div>
            )
          }
        </div>
      </div>
      <section className="md:w-1/4">
        <Doughnut data={data} />
      </section>
    </Layout>
  );
}

export default Dashboard;

