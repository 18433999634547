import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Header } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const ConfirmEmail = () => {
    const navigate = useNavigate();
    const { confirmEmail } = useStateContext();
    const [loading, setLoading] = useState(true);
    const [resStatusOk, setResStatusOk] = useState(false)
    const { id } = useParams();

    useEffect(() => {
        const confEmail = async () => {
            try {
                const response = await confirmEmail(id);
                if (response.status === "Ok") {
                    toast.success(response.message);
                    setResStatusOk(true)
                } else {
                    toast.error(response.message);
                    navigate('/get-otp');
                }
            } catch (error) {
                toast.error('An error occurred.');
            } finally {
                setLoading(false);
            }
        };

        confEmail();
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="bg-secondary-dark-bg rounded-lg md:w-1/2 w-5/6 m-auto px-5 md:px-10 pb-10 pt-5">
                <Header category="App" title={loading ? 'Verifying...' : resStatusOk ? 'Success' : 'Error'} />
                <p className='mt-3 text-gray-400 text-sm'>
                    To login click <Link className="underline text-blue-600" to="/get-otp">here</Link>.
                </p>
            </div>
        </>
    );
};

export default ConfirmEmail;
