import React from 'react';

const Header = ({ category, title }) => (
  <div className=" mb-5">
    <p className='text-gray-400'>
      {category}
    </p>
    <p className='text-3xl font-extrabold tracking-tight text-gray-500'>
      {title}
    </p>
  </div>
);

export default Header;
