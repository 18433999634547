import React from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { NavLink } from 'react-router-dom';
import { HiChevronDoubleRight } from "react-icons/hi";
import { HiMiniBuildingOffice, HiOutlineArrowsRightLeft } from "react-icons/hi2";
import { TbLogout2 } from "react-icons/tb";
import { IoHomeSharp } from "react-icons/io5";

const Menu = () => {
  const { currentColor, setShowRightSideMenu, logout } = useStateContext();

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className="float-right h-screen text-gray-200 bg-gray-700 w-100">
        <div className="flex justify-between items-center p-4 ml-4">
          <button
            type="button"
            onClick={() => setShowRightSideMenu(false)}
            style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <HiChevronDoubleRight />
          </button>

        </div>
        <div className="mt-5 flex flex-col items-center">
          <NavLink
            className={'p-5 mb-5 text-2xl rounded-full'}
            to={'/'}
            key={'home'}
            onClick={() => setShowRightSideMenu(false)}
            style={({ isActive }) => ({
              backgroundColor: isActive ? currentColor : '',
            })}
          >
            <IoHomeSharp />
          </NavLink>
          <NavLink
            className={'p-5 mb-5 text-2xl rounded-full'}
            to={'/accounts'}
            key={'accounts'}
            onClick={() => setShowRightSideMenu(false)}
            style={({ isActive }) => ({
              backgroundColor: isActive ? currentColor : '',
            })}
          >
            <HiMiniBuildingOffice />
          </NavLink>
          <NavLink
            className={'p-5 mb-5 text-2xl rounded-full'}
            to={'/transactions'}
            key={'transactions'}
            onClick={() => setShowRightSideMenu(false)}
            style={({ isActive }) => ({
              backgroundColor: isActive ? currentColor : '',
            })}
          >
            <HiOutlineArrowsRightLeft />
          </NavLink>
          <button onClick={() => { logout() }} className='p-5 mb-5 text-2xl rounded-full hover:bg-red-200'><TbLogout2 /></button>
        </div>

      </div>
    </div>
  );
};

export default Menu;
