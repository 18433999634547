import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Dashboard, Accounts, GetOTP, SignUp, ValidateOTP, ConfirmEmail, Transactions} from './pages';
import './App.css';
import { useStateContext } from './contexts/ContextProvider' 


const App = () => {
  const { currentMode, token } = useStateContext();
  const ProtectedRoute = ({ children }) => {
    if (!token) {
      return <Navigate to="/get-otp" />;
    }
    return children;
  };
  return (
    <div className="bg-main-dark-bg pt-5">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/accounts" element={
            <ProtectedRoute>
              <Accounts />
            </ProtectedRoute>
          } />
          <Route path="/transactions" element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          } />
          <Route path="/get-otp" element={<GetOTP />} />
          <Route path="/confirm-email/:id" element={<ConfirmEmail />} />

          <Route path="/validate-otp/:id" element={<ValidateOTP />} />
          <Route path="/sign-up" element={<SignUp />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
