import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { Header } from '../components';
import { Layout } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';

const Transactions = () => {
    const [rerender, setRerender] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [transactionName, setTransactionName] = useState('');
    const [transactionTypeId, setTransactionTypeId] = useState('');
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [account, setAccount] = useState({});
    const [showAccountDetails, setShowAccountDetails] = useState(false);
    const { getUsersAccounts, createTransaction, updateAccount, deleteAccount, getTransactionTypes, getUsersTransactions } = useStateContext();

    useEffect(() => {
        const fetchData = async () => {
            const data = await getUsersAccounts();
            setAccounts(data.accounts);
        };
        fetchData();
        const fetchUsersTransactions = async () => {
            const data = await getUsersTransactions()
            setTransactions(data?.transactions)
        };
        fetchUsersTransactions();
    }, [rerender])

    useEffect(() => {
        const fetchTransactionTypes = async () => {
            const data = await getTransactionTypes();
            setTransactionTypes(data.transactionTypes);
        };

        fetchTransactionTypes();
    }, [])

    const handleCreateTransaction = (e) => {
        setRerender(!rerender)
        e.preventDefault()
        if (!transactionName) {
            toast("Transaction name can not be empty!");
            return false;
        }
        if (!accountId) {
            toast("Please select an account!");
            return false;
        }
        if (transactionAmount === 0) {
            toast("Please provide transaction amount!");
            return false;
        }

        createTransaction({
            Id: uuidv4(),
            TransactionName: transactionName,
            AccountId: accountId,
            TransactionAmount: transactionAmount,
            TransactionTypeId: transactionTypeId
        })
    }
    const handleShowAccountDetails = (item) => {
        setRerender(!rerender)
        setShowAccountDetails(true)
        setAccount(item)
    }
    const handleUpdateDetails = () => {
        setRerender(!rerender)
        updateAccount(account)
        setShowAccountDetails(false)
    }
    const handleDeleteAccount = () => {
        setRerender(!rerender)
        deleteAccount(account)
        setShowAccountDetails(false)
    }
    return (
        <Layout>
            <ToastContainer />
            <div className="w-5/6 m-auto m-2 md:m-10 mt-10 p-5 md:p-10 bg-secondary-dark-bg rounded-3xl">
                <Header category="App" title="Transactions" />
                <div className='mt-5 w-full md:w-400'>
                    <form className="max-w-md mx-auto mb-5">
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                onChange={(e) => setTransactionName(e.target.value)}
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none" />
                            <label htmlFor="floating_email" className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Transaction name</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <select
                                onChange={(e) => setAccountId(e.target.value)}
                                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none">
                                <option value="">Choose account</option>
                                {accounts?.map((el, index) => (
                                    <option value={el.Id} key={index}>{el.AccountName}</option>
                                ))}
                            </select>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <input
                                onChange={(e) => setTransactionAmount(e.target.value)}
                                value={transactionAmount}
                                type="number"
                                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-300 appearance-none" />
                            <label
                                className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Transaction amount</label>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <h3 className="mb-4 text-gray-500 text-sm font-sm">Transaction type</h3>
                            <ul className="w-48 text-sm font-medium bg-gray-500 bg-white border border-gray-200 rounded-lg">
                                {transactionTypes?.map((el, index) => (
                                    <li key={el.Id} className="w-full border-b border-gray-200 rounded-t-lg">
                                        <div className="flex items-center ps-3">
                                            <input onChange={(e) => {
                                                setTransactionTypeId(e.target.value)
                                            }} name="transactionType" type="radio" id={el.Id} value={el.Id} className="w-4 h-4 text-blue-600 bg-gray-500 border-gray-300" />
                                            <label htmlFor={el.Id} className="w-full py-3 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{el.TransactionType}</label>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <button onClick={handleCreateTransaction} className="bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center text-gray-300">Create</button>
                    </form>
                    {transactions?.map((item, index) => (

                        <div key={index} className='relative bg-gray-600 flex justify-between items-center mt-2 p-2 rounded-2xl hover:shadow-lg cursor-pointer'>
                            <div className='flex gap-4'>
                                <button
                                    type='button'
                                    style={{
                                        color: "#03C9D7",
                                        backgroundColor: "#E5FAFB"
                                    }}
                                    className='text-2xl rounded-lg p-4 hover:drop-shadow-xl'
                                >
                                    <BsCurrencyDollar />
                                </button>
                                <div>
                                    <p className='text-md text-gray-200 font-semibold'>
                                        {item.Transactions.TransactionName}
                                    </p>
                                    <p className='text-sm text-gray-400'>
                                        {item.Transactions.TransactionAmount}
                                    </p>
                                    <p className='text-xs text-gray-400'>
                                        {item.Accounts.AccountName}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default Transactions;
