import React, { useEffect } from 'react';
import { useStateContext } from '../contexts/ContextProvider';
import { HiChevronDoubleLeft } from "react-icons/hi";


const Navbar = () => {

  const { setShowRightSideMenu, setActiveMenu, screenSize, setScreenSize } = useStateContext();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth)
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false)
    } else {
      setActiveMenu(true)
    }
  }, [screenSize])


  return (
    <>
      <button
        type="button"
        onClick={() => setShowRightSideMenu(true)}
        style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
        className="fixed right-5 text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
      >
        <HiChevronDoubleLeft />
      </button>
    </>
  )
};

export default Navbar;
